import Vue from 'vue'
import VueRouter from 'vue-router'
import { defineAsyncComponent } from 'vue'
// import HomeView from '../views/HomeView.vue'
// import HomeView from '../views/AboutView.vue'
// import ArticlesVente from '../views/VentesAction/VentesAction.vue'
// import ArticlesList from '../views/ArticlesList/ArticlesList.vue'
// import ArticlesCategorie from '../views/ArticlesCategorie/ArticlesCategorie.vue'
const DasboardPage = defineAsyncComponent(() => import('../views/DasboardPage/DasboardPage.vue'))
const EmployeList = defineAsyncComponent(() => import('../views/EmployeList/EmployeList.vue'))
const EmployeAdd = defineAsyncComponent(() => import('../views/EmployeAdd/EmployeAdd.vue'))
const ParametresApp = defineAsyncComponent(() => import('../views/ParametresApp/ParametresApp.vue'))
const EmployePresence = defineAsyncComponent(() => import('../views/EmployePresence/EmployePresence.vue'))
const EmployeCheckList = defineAsyncComponent(() => import('../views/EmployeCheckList/EmployeCheckList.vue'))
const EmployePaie = defineAsyncComponent(() => import('../views/EmployePaie/EmployePaie.vue'))
const EmployePaieGeneral = defineAsyncComponent(() => import('../views/EmployePaieGeneral/EmployePaieGeneral.vue'))
const EmployeAbsenceList = defineAsyncComponent(() => import('../views/EmployeAbsenceList/EmployeAbsenceList.vue'))
const ControlPresence = defineAsyncComponent(() => import('../views/ControlPresence/ControlPresence.vue'))
const ControlTravailleur = defineAsyncComponent(() => import('../views/ControlTravailleur/ControlTravailleur.vue'))
const UtilisateursParam = defineAsyncComponent(() => import('../views/ParametresApp/UtilisateursParam/UtilisateursParam.vue'))

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DasboardPage',
    component: DasboardPage
  },
  {
    path: '/dashboard',
    name: 'DasboardPage',
    component: DasboardPage
  },
  {
    path: '/employe-list',
    name: 'EmployeList',
    component: EmployeList
  },
  {
    path: '/employe-new',
    name: 'EmployeAdd',
    component: EmployeAdd
  },
  {
    path: '/employe-edit/:id',
    name: 'EmployeEdit',
    component: EmployeAdd
  },
  {
    path: '/parametres',
    name: 'ParametresApp',
    component: ParametresApp
  },
  {
    path: '/employe-presence',
    name: 'EmployePresence',
    component: EmployePresence
  },
  {
    path: '/historique-scan',
    name: 'EmployeCheckList',
    component: EmployeCheckList
  },
  {
    path: '/fiche-paie',
    name: 'EmployePaie',
    component: EmployePaie
  },
  {
    path: '/historique-absence',
    name: 'EmployeAbsenceList',
    component: EmployeAbsenceList
  },
  {
    path: '/controle-presence',
    name: 'ControlPresence',
    component: ControlPresence
  },
  {
    path: '/controle-presence-mobile',
    name: 'ControlPresenceMobile',
    component: ControlPresence
  },
  {
    path: '/controle-travailleur',
    name: 'ControlTravailleur',
    component: ControlTravailleur
  },
  {
    path: '/controle-travailleur-mobile',
    name: 'ControlTravailleurMobile',
    component: ControlTravailleur
  },
  {
    path: '/utilisateurs',
    name: 'UtilisateursParam',
    component: UtilisateursParam
  },
  {
    path: '/etat-paie-general',
    name: 'EmployePaieGeneral',
    component: EmployePaieGeneral
  },
]

// const router = new VueRouter({
//   routes
// })

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
