import Vue from 'vue'
import App from './App.vue'
import AppNoHeader from './AppNoHeader.vue'
import Login from './views/Login/LoginForm-2.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueCookies from 'vue-cookies'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


// import LoadingComponent from './components/LoadingComponent.vue'
// Vue.component('loadingPage',{ template: LoadingComponent})

// import axios from 'axios'
// import VueToast from 'vue-toast-notification';
// Vue.use(VueToast);
// import NProgress from 'vue-nprogress'
// Vue.use(NProgress)

Vue.use(VueCookies);

Vue.config.productionTip = false


Vue.BASE_URL = Vue.prototype.BASE_URL = '';
// Vue.BASE_URL = Vue.prototype.BASE_URL = '/PROJETS/MontLitchis/AppAdministration/';

// console.log("dnacalyspouserid");
// console.log(Vue.$cookies);
// console.log(Vue.$cookies.get("dnacalyspouserid"));

// var app = null;

// alert(this.$route.name);

if(window.location.href.indexOf("controle-presence-mobile") > 0 || window.location.href.indexOf("controle-travailleur-mobile") > 0) {
  const app = new Vue({
    vuetify,
    router,
    render: h => h(AppNoHeader)
  }).$mount('#app');

  router.beforeEach((to, from, next) => {
    app.$emit('change-load', 'loading');
    next();

  })

  router.afterEach(() => {
    setTimeout(() => app.$emit('change-load', 'loaded'), 500)
  })
}

else if(Vue.$cookies.get("dnacalyspouserid") != "" && Vue.$cookies.get("dnacalyspouserid") != null) {

  const app = new Vue({
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app');

  router.beforeEach((to, from, next) => {
    app.$emit('change-load', 'loading');
    next();

  })

  router.afterEach(() => {
    setTimeout(() => app.$emit('change-load', 'loaded'), 500)
  })


} else {
  
  new Vue({
    vuetify,
    router,
    render: h => h(Login)
  }).$mount('#app');

}





// app.$emit('change-load', 'loading');
// app.$emit('change-load', 'loaded');

