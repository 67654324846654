
import axios from "axios";
import $ from 'jquery';
// import router from '../../router'

// import vuetify from '../../plugins/vuetify'
// import App from '../../App.vue'
// import Vue from 'vue'

export default {
    name: 'LoginForm',
    components: {
    },
  
    data () {
      return {
        showPassword: false,
        valid: null,
        rules: {
            textRequis: [
                v => !!v || 'Champs réquis',
            ],
            categorie: [val => (val || '').length > 0 || 'Catégorie réquis'],
        },
        snackBar: {
            message: "",
            color: "grey darken-4",
            status: false
        },
      }
    },
    methods: {
        
        submitForm () {

            let that = this;
            
            if(this.$refs.form.validate() == true) {
                this.statutBtnEnregistrer = false;
                var link = that.BASE_URL + "/users/loginaction";
                
                axios.post(link, $("#formulaire").serialize()).then(function (response) {
                    that.statutBtnEnregistrer = true;
                    if (response.data.error === "") {
                        that.dialogModal = false;
                        that.snackBar = {
                            message: response.data.message,
                            color: "green darken-2",
                            status: true,
                            position: top
                        };
                        // router.push({ name: 'ArticlesList'});
                        location.reload();
                        // new Vue({
                        //     vuetify,
                        //     router,
                        //     render: h => h(App)
                        //   }).$mount('#app');
                    } else {
                        
                        that.snackBar = {
                            message: response.data.error,
                            color: "red darken-2",
                            status: true
                        };
                    }
                });
            }
            
        },
    },
    watch: {
      
    },
    mounted: function () {
      
    }
  };