<template>
  <v-app id="app">
    
    <v-sheet
          id="scrolling-techniques-7"
          class="overflow-y-auto grey lighten-4"
          style="height: calc(100vh + 80px);"
        >

        <!-- style="height: calc(100vh - 65px);" -->
        <v-main class="">
          
          <router-view/>
          
        </v-main>
      </v-sheet>

      <v-overlay
        :opacity="0.5"
          :value="overlay"
          color="blue-grey lighten-5"
      >
          <v-progress-circular color="blue-grey darken-3" :width="5" indeterminate size="80"></v-progress-circular>
          
      </v-overlay>

      
  </v-app>
  
</template>

<style>
/* .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px !important;
} */
#menu-app-bar .v-toolbar__content, .v-toolbar__extension {
  padding: 0px !important;
}
</style>

<script>

// import $ from 'jquery';
import axios from "axios";

// import router from './router'
import Vue from 'vue'

export default {
  name: 'App',

  components: {
    // listesArticle,
    // articlesCategorie
  },

  data () {
    return {
      overlay: false,
      dialogConfirm: false,
      showPasswordCurrent: false,
      showPassword: false,
      showPasswordConfirm: false,
      dialogModal: false,
      valid: null,
      searchMenu: "",
      snackBar: {
          message: "",
          color: "grey darken-4",
          status: false
      },
      drawer: false,
      MenuItems: [],
      MenuItemsAdmin: [
        {
          action: 'mdi-view-dashboard',
          active: true,
          items: [
            { title: 'Tableau de bord', action: 'mdi-menu-right', url: 'dashboard' },
            { title: 'Controle de presence', action: 'mdi-menu-right', url: 'controle-presence' }
          ],
          title: 'Etats',
        },
        {
          action: 'mdi-account-multiple',
          active: true,
          items: [
            { title: 'Liste des employés', action: 'mdi-menu-right', url: 'employe-list' },
            { title: 'Ajouter un employé', action: 'mdi-menu-right', url: 'employe-new' },
            // { title: 'Fiche employé', action: 'mdi-menu-right', url: '' },
          ],
          title: 'Employés',
        },
        {
          action: 'mdi-currency-usd',
          active: true,
          items: [
            { title: 'Présence des employés', action: 'mdi-menu-right', url: 'employe-presence' },
            { title: 'Historique des scans', action: 'mdi-menu-right', url: 'historique-scan' },
            { title: 'Historique des absences', action: 'mdi-menu-right', url: 'historique-absence' },
            { title: 'Fiche de paie des employés', action: 'mdi-menu-right', url: 'fiche-paie' },
          ],
          title: 'Présence & Paie',
        },
        {
          action: 'mdi-application-cog-outline',
          active: true,
          items: [
            { title: 'Paramètres de l\'application', action: 'mdi-menu-right', url: 'parametres' },
            // { title: 'Informations sur la société', action: 'mdi-menu-right', url: '#' },
            { title: 'Liste des utilisateurs', action: 'mdi-menu-right', url: 'utilisateurs' },
            { title: 'Mon compte', action: 'mdi-menu-right', url: '#' },
          ],
          title: 'Paramètres',
        },
        
      ],
      MenuItemsControlleur: [
        {
          action: 'mdi-view-dashboard',
          active: true,
          items: [
            { title: 'Tableau de bord', action: 'mdi-menu-right', url: 'dashboard' },
            { title: 'Controle de presence', action: 'mdi-menu-right', url: 'controle-presence' }
          ],
          title: 'Etats',
        },
        {
          action: 'mdi-currency-usd',
          active: true,
          items: [
            { title: 'Présence des employés', action: 'mdi-menu-right', url: 'employe-presence' },
            { title: 'Historique des scans', action: 'mdi-menu-right', url: 'historique-scan' },
            { title: 'Historique des absences', action: 'mdi-menu-right', url: 'historique-absence' },
          ],
          title: 'Présence & Paie',
        },
        {
          action: 'mdi-application-cog-outline',
          active: true,
          items: [
            { title: 'Mon compte', action: 'mdi-menu-right', url: '#' },
          ],
          title: 'Paramètres',
        },
        
      ],
      MenuItemsSearch: [],
      user_info: []
    }
  },
  methods: {

    onChangeLoad: function (status) {
      console.log(status);
      if (status == "loading") {
        this.overlay = true;
      } else {
        this.overlay = false;
      }
    },
    getOneEdit() {
        axios.get(this.BASE_URL + "/users/get").then(response => {
            this.crudForm = response.data;
            this.dialogModal = true;
        });
    },
    fetchMenu() {

      let motCle = this.searchMenu.trim();
      // console.log(motCle);
      this.MenuItemsSearch = [];
      // console.log(this.MenuItems);
      var myTableau = JSON.parse(JSON.stringify(this.MenuItems)); //this.MenuItems.slice(); //Array.from(this.MenuItems); //[...this.MenuItems];
      // console.log(this.MenuItems);

      if(motCle != "") {

        

        for(var index = 0; index < myTableau.length; index++) {

          myTableau[index].items = myTableau[index].items.filter(item => {
            return item.title.toLowerCase().includes(motCle.toLowerCase());
          });

        }
        myTableau = myTableau.filter(item => {
          return item.items.length > 0;
        })

      }
      this.MenuItemsSearch = JSON.parse(JSON.stringify(myTableau)); //myTableau.slice(); //Array.from(myTableau); //[...myTableau];
      
    },
    logout() {
      Vue.$cookies.remove("dnacalyspouserid");
      location.reload();
    },
  },
  mounted: function () {
    // document.body.style.zoom = 0.92;
    this.fetchMenu();
    // alert(this.$route.name);

  },
  computed: {
    
  },
  watch: {

  },
  created: function () {
    this.$parent.$on("change-load", this.onChangeLoad);
    // if (window.matchMedia("only screen and (max-width: 760px)").matches == true) {
    //   var elem = document.getElementById("pageprincipal");
    //   this.enterFullScreen(elem);
    // }
    // console.log("dnamonlitchiuser");
    // console.log(Vue.$cookies.get("dnamonlitchiuser"))
    this.user_info = Vue.$cookies.get("dnamonlitchiuser") != "" && Vue.$cookies.get("dnamonlitchiuser") != null ? Vue.$cookies.get("dnamonlitchiuser") : {nom:'', photo: '', role: ''};
    if(Vue.$cookies.get("dnamonlitchiuser") != "" && Vue.$cookies.get("dnamonlitchiuser") != null)
      this.MenuItems = this.user_info.role != 'Controlleur' ? this.MenuItemsAdmin : this.MenuItemsControlleur;
  },
};
</script>
