<template>
  <v-app id="app">
    <v-app-bar
      flat
      outlined
      scroll-target="#scrolling-techniques-7"
      elevation="2"
      style="max-height: 64px;"
      color="blue-grey darken-3"
      v-if="$route.name != 'ControlPresenceMobile'"
    >
      <v-app-bar-nav-icon color="white" @click="drawer=true"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title color="white">Application pour mon litchis</v-toolbar-title> -->
      <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            src="../public/assets/img/LogoMonLitchis5.png"
            width="100"
          />
      <v-spacer></v-spacer>
      
      <v-btn color="grey lighten-5"
          fab
          small 
          dark 
          outlined
          class="mr-1"
          elevation="0"
          @click="getOneEdit"
          title="MON PROFIL">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn color="grey lighten-5"
          fab
          small 
          dark 
          outlined
          class="mr-1"
          elevation="0"
          @click="dialogConfirm = true"
          title="SE DECONNECTER">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="blue-grey lighten-5"
      width="280"
      v-if="$route.name != 'ControlPresenceMobile'"
    >
      <!-- temporary -->
      <v-app-bar
        flat
        outlined
        elevation="0"
        style="max-height: 70px;"
        color="blue-grey darken-3"
        id="menu-app-bar"
      >
        <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="100"
            src="../public/assets/img/LogoMonLitchis4.png"
            style="width: 100%;"
          />
        <!-- <div class="d-flex align-left">
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="100"
            src="../public/assets/img/LogoMonLitchis3.png"
            width="200"
          />
        </div>
          <v-spacer></v-spacer> -->
      </v-app-bar>
      <div style="background-color: #37474F;" class="py-2">
        <!-- <v-img
          height="80px"
          src="../public/assets/img/cover.jpg"
        > -->
          <!-- src="../public/assets/img/cover.jpg" -->
          <div class="d-flex flex-row justify-center">
            <div class="flex-fill text-uppercase py-2 pl-3" style="width: 30px;">
              <v-avatar size="55" color="grey lighten-2">
                <img
                  alt="user"
                  :src="user_info.photo == '' ? '../public/assets/img/profil.png' : BASE_URL + '/assets/utilisateurs/' + user_info.id + '/' + user_info.photo"
                >
              </v-avatar>
            </div>
            <div class="flex-fill py-3">
              <div class="font-weight-medium text-white" style="color: #eee;">{{ user_info.nom }}</div>
              <div class=" text-white" style="color: #eee;">{{ user_info.role }}</div>
            </div>
          </div>
          
        <!-- </v-img> -->
      </div>
      
      
      <v-list
        nav
        dense
        >
        <v-subheader 
        class="py-4 px-0" style="padding: 0px;">
          
          <v-text-field
            label="Rechercher"
            outlined
            sm
            v-model="searchMenu"
            @input="fetchMenu"
            olo dense hide-details
            ref="searchArticle"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>

        </v-subheader>

        <v-list-group
          active-class="blue-grey--text darken-3 text--accent-1"
          v-for="item in MenuItemsSearch"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          
          class="mt-1"
        >
        <!-- no-action -->
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item-content style="background: #CFD8DC; border-radius: 5px;" class="px-2">
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              link
              active-class="blue-grey--text text--darken-4 blue-grey lighten-3"
              :to="'/'+child.url"
            >
            <!-- BASE_URL +  -->
              <!-- <v-list-item-content> -->
                <v-list-item-icon class="mr-1">
                  <v-icon v-text="child.action"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              <!-- </v-list-item-content> -->
            </v-list-item>
          </v-list-item-content>
          
        </v-list-group>
      </v-list>
        
    </v-navigation-drawer>

    
    <v-sheet
          id="scrolling-techniques-7"
          class="overflow-y-auto grey lighten-4"
          :style="$route.name != 'ControlPresenceMobile' ? 'height: calc(100vh + 40px);': 'height: calc(100vh + 80px);'"
        >

        <!-- style="height: calc(100vh - 65px);" -->
        <v-main class="">
          
          <router-view/>
          
        </v-main>
      </v-sheet>

      <v-dialog
        v-model="dialogConfirm"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="text-h6 blue-grey--text">
            Confirmation
          </v-card-title>
          <v-card-text>Voulez-vous vraiment vous déconnecter?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="logout"
            >
              Se déconnecter
            </v-btn>
            <v-btn
              color="dark darken-1"
              text
              @click="dialogConfirm = false"
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-overlay
        :opacity="0.5"
          :value="overlay"
          color="blue-grey lighten-5"
      >
          <v-progress-circular color="blue-grey darken-3" :width="5" indeterminate size="80"></v-progress-circular>
          
      </v-overlay>

      
  </v-app>
  
</template>

<style>
/* .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px !important;
} */
#menu-app-bar .v-toolbar__content, .v-toolbar__extension {
  padding: 0px !important;
}
</style>

<script>

// import $ from 'jquery';
import axios from "axios";

// import router from './router'
import Vue from 'vue'

export default {
  name: 'App',

  components: {
    // listesArticle,
    // articlesCategorie
  },

  data () {
    return {
      overlay: false,
      dialogConfirm: false,
      showPasswordCurrent: false,
      showPassword: false,
      showPasswordConfirm: false,
      dialogModal: false,
      valid: null,
      searchMenu: "",
      snackBar: {
          message: "",
          color: "grey darken-4",
          status: false
      },
      drawer: false,
      MenuItems: [],
      MenuItemsAdmin: [
        {
          action: 'mdi-view-dashboard',
          active: true,
          items: [
            { title: 'Tableau de bord', action: 'mdi-menu-right', url: 'dashboard' },
            { title: 'Etats de paie général', action: 'mdi-menu-right', url: 'etat-paie-general' },
            { title: 'Controle de presence', action: 'mdi-menu-right', url: 'controle-presence' },
            { title: 'Travailleur par affectation', action: 'mdi-menu-right', url: 'controle-travailleur' }
          ],
          title: 'Etats',
        },
        {
          action: 'mdi-account-multiple',
          active: true,
          items: [
            { title: 'Liste des employés', action: 'mdi-menu-right', url: 'employe-list' },
            { title: 'Ajouter un employé', action: 'mdi-menu-right', url: 'employe-new' },
            // { title: 'Fiche employé', action: 'mdi-menu-right', url: '' },
          ],
          title: 'Employés',
        },
        {
          action: 'mdi-currency-usd',
          active: true,
          items: [
            { title: 'Présence des employés', action: 'mdi-menu-right', url: 'employe-presence' },
            { title: 'Historique des scans', action: 'mdi-menu-right', url: 'historique-scan' },
            { title: 'Historique des absences', action: 'mdi-menu-right', url: 'historique-absence' },
            { title: 'Fiche de paie des employés', action: 'mdi-menu-right', url: 'fiche-paie' },
          ],
          title: 'Présence & Paie',
        },
        {
          action: 'mdi-application-cog-outline',
          active: true,
          items: [
            { title: 'Paramètres de l\'application', action: 'mdi-menu-right', url: 'parametres' },
            // { title: 'Informations sur la société', action: 'mdi-menu-right', url: '#' },
            { title: 'Liste des utilisateurs', action: 'mdi-menu-right', url: 'utilisateurs' },
            { title: 'Mon compte', action: 'mdi-menu-right', url: '#' },
          ],
          title: 'Paramètres',
        },
        
      ],
      MenuItemsControlleur: [
        {
          action: 'mdi-view-dashboard',
          active: true,
          items: [
            { title: 'Tableau de bord', action: 'mdi-menu-right', url: 'dashboard' },
            { title: 'Controle de presence', action: 'mdi-menu-right', url: 'controle-presence' },
            { title: 'Travailleur par affectation', action: 'mdi-menu-right', url: 'controle-travailleur' }
          ],
          title: 'Etats',
        },
        {
          action: 'mdi-currency-usd',
          active: true,
          items: [
            { title: 'Présence des employés', action: 'mdi-menu-right', url: 'employe-presence' },
            { title: 'Historique des scans', action: 'mdi-menu-right', url: 'historique-scan' },
            { title: 'Historique des absences', action: 'mdi-menu-right', url: 'historique-absence' },
          ],
          title: 'Présence & Paie',
        },
        {
          action: 'mdi-application-cog-outline',
          active: true,
          items: [
            { title: 'Mon compte', action: 'mdi-menu-right', url: '#' },
          ],
          title: 'Paramètres',
        },
        
      ],
      MenuItemsSearch: [],
      user_info: []
    }
  },
  methods: {

    onChangeLoad: function (status) {
      console.log(status);
      if (status == "loading") {
        this.overlay = true;
      } else {
        this.overlay = false;
      }
    },
    getOneEdit() {
        axios.get(this.BASE_URL + "/users/get").then(response => {
            this.crudForm = response.data;
            this.dialogModal = true;
        });
    },
    fetchMenu() {

      let motCle = this.searchMenu.trim();
      // console.log(motCle);
      this.MenuItemsSearch = [];
      // console.log(this.MenuItems);
      var myTableau = JSON.parse(JSON.stringify(this.MenuItems)); //this.MenuItems.slice(); //Array.from(this.MenuItems); //[...this.MenuItems];
      // console.log(this.MenuItems);

      if(motCle != "") {

        

        for(var index = 0; index < myTableau.length; index++) {

          myTableau[index].items = myTableau[index].items.filter(item => {
            return item.title.toLowerCase().includes(motCle.toLowerCase());
          });

        }
        myTableau = myTableau.filter(item => {
          return item.items.length > 0;
        })

      }
      this.MenuItemsSearch = JSON.parse(JSON.stringify(myTableau)); //myTableau.slice(); //Array.from(myTableau); //[...myTableau];
      
    },
    logout() {
      Vue.$cookies.remove("dnacalyspouserid");
      location.reload();
    },
  },
  mounted: function () {
    // document.body.style.zoom = 0.92;
    this.fetchMenu();
    // alert(this.$route.name);

  },
  computed: {
    
  },
  watch: {

  },
  created: function () {
    this.$parent.$on("change-load", this.onChangeLoad);
    // if (window.matchMedia("only screen and (max-width: 760px)").matches == true) {
    //   var elem = document.getElementById("pageprincipal");
    //   this.enterFullScreen(elem);
    // }
    // console.log("dnamonlitchiuser");
    // console.log(Vue.$cookies.get("dnamonlitchiuser"))
    this.user_info = Vue.$cookies.get("dnamonlitchiuser") != "" && Vue.$cookies.get("dnamonlitchiuser") != null ? Vue.$cookies.get("dnamonlitchiuser") : {nom:'', photo: '', role: ''};
    if(Vue.$cookies.get("dnamonlitchiuser") != "" && Vue.$cookies.get("dnamonlitchiuser") != null)
      this.MenuItems = this.user_info.role != 'Controlleur' ? this.MenuItemsAdmin : this.MenuItemsControlleur;
  },
};
</script>
